export default {
  menu: {
    'about-us': 'FR',
    projects: 'FR',
    investors: 'FR',
    contact: 'FR'
  },
  slider: {
    readmore: 'FR'
  },
  about: {
    title: 'FR',
    subtitle: 'FR',
    text: 'FR'
  },
  projects: {
    title: 'FR',
    subtitle: 'FR',
    aprTitle: 'FR',
    aprSubtitle: 'FR',
    aprReadmore: 'FR',
    name: 'FR',
    subtitle2: 'FR',
    text1: 'FR',
    text2: 'FR'
  },
  investors: {
    title: 'FR',
    text: 'FR',
    point1: 'FR',
    point2: 'FR',
    point3: 'FR',
    point4: 'FR',
    bottomText: 'FR'
  },
  contact: {
    title: 'FR',
    subtitle: 'FR',
    name: 'FR',
    address: 'FR',
    email: 'FR',
    phone: 'FR',
    yourName: 'FR',
    yourEmail: 'FR',
    subject: 'FR',
    text: 'FR',
    sendButton: 'FR'
  },
  validation: {
    'check-form': 'FR',
    required: 'FR',
    min: 'FR',
    max: 'FR',
    email: 'FR'
  },
  'locale-set': 'FR',
  'message-sent': 'FR',
  loading: 'FR'
}
