<template>
  <Header />
  <Slider />
  <About />
  <Projects />
  <Investors />
  <Contact />
  <Footer />
</template>

<script>
import Header from '../components/Header'
import Slider from '../components/Slider'
import About from '../components/About'
import Projects from '../components/projects/Projects'
import Investors from '../components/Investors'
import Contact from '../components/contact/Contact'
import Footer from '../components/Footer'

export default {
  components: {
    Header,
    Slider,
    About,
    Projects,
    Investors,
    Contact,
    Footer
  }
}
</script>

<style lang="scss" scoped></style>
