import { createI18n } from 'vue-i18n'

import ru from './ru'
import en from './en'
import fr from './fr'

const messages = {
  ru,
  en,
  fr
}

export default createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages
})
