<template>
  <section id="contact">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <div class="sec-heading mx-auto">
            <h1>{{ $t('contact.title') }}</h1>
            <p>
              {{ $t('contact.subtitle') }}
            </p>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-3 col-md-6">
          <div
            class="contact-box"
            data-aos="fade-right"
            data-aos-duration="600"
          >
            <i class="ti-info"></i>
            <h4>{{ $t('contact.name') }}</h4>
            <p>{{ contactData.businessName }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div
            class="contact-box"
            data-aos="fade-right"
            data-aos-duration="600"
            data-aos-delay="200"
          >
            <i class="ti-map-alt"></i>
            <h4>{{ $t('contact.address') }}</h4>
            <p>{{ contactData.address }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div
            class="contact-box"
            data-aos="fade-right"
            data-aos-duration="600"
            data-aos-delay="400"
          >
            <i class="ti-email"></i>
            <h4>{{ $t('contact.email') }}</h4>
            <p>
              <br />
              <a :href="`mailto:${contactData.email}`">{{
                contactData.email
              }}</a>
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div
            class="contact-box"
            data-aos="fade-right"
            data-aos-duration="600"
            data-aos-delay="600"
          >
            <i class="ti-headphone"></i>
            <h4>{{ $t('contact.phone') }}</h4>
            <p>
              <br />
              <a :href="`tel:${contactData.tel}`">{{ contactData.tel }}</a>
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <Map />
        <Form />
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
import Map from './Map.vue'
import Form from './Form.vue'
import { contactData } from '../../constants'

export default {
  name: 'Contact',
  components: {
    Map,
    Form
  },
  setup() {
    return { contactData }
  }
}
</script>

<style></style>
