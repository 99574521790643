<template>
  <section
    class="image-bg"
    style="
      background: #0b4f6c url(assets/img/projects/projects-bg.png) no-repeat;
    "
    id="projects"
  >
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <div class="sec-heading light mx-auto">
            <h1>{{ $t('projects.title') }}</h1>
            <p>{{ $t('projects.subtitle') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>

  <section class="pd-0 overlay-top">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-10 col-sm-12">
          <div class="row mrg-0 bg-white box-shadow pt-5 border--radius">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div
                data-aos="fade-right"
                data-aos-duration="600"
                data-aos-delay="300"
                class="large-features-2 style-1 shadow-0 border-0"
                @click="openModal('apr')"
              >
                <div class="large-features-2-thumb">
                  <img
                    src="assets/img/projects/kg.png"
                    class="img-responsive"
                    alt=""
                  />
                </div>
                <div class="large-features-2-detail">
                  <h4>{{ $t('projects.aprTitle') }}</h4>
                  <p class="text-justify">{{ $t('projects.aprSubtitle') }}</p>
                  <a class="read-more" href="#"
                    >{{ $t('projects.aprReadmore') }}
                    <i class="ti-angle-right zoom-7" />
                  </a>
                </div>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="600"
                data-aos-delay="600"
                class="large-features-2 style-1 shadow-0 border-0"
                @click="openModal('del')"
              >
                <div class="large-features-2-thumb">
                  <img
                    src="assets/img/projects/kg.png"
                    class="img-responsive"
                    alt=""
                  />
                </div>
                <div class="large-features-2-detail">
                  <h4>{{ $t('projects.delTitle') }}</h4>
                  <p class="text-justify">{{ $t('projects.delSubtitle') }}</p>
                  <a class="read-more" href="#"
                    >{{ $t('projects.delReadmore') }}
                    <i class="ti-angle-right zoom-7" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <h2>
            <span class="theme-cl">{{ $t('projects.name') }}</span>
            {{ $t('projects.subtitle2') }}
          </h2>
          <p class="text-justify">
            <br />
            {{ $t('projects.text1') }}
          </p>
          <p class="text-justify">
            <br />
            {{ $t('projects.text2') }}
            <a :href="`mailto:${contactData.email}`">{{ contactData.email }}</a
            >.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img
            src="assets/img/projects/1.JPG"
            class="img-fluid mx-auto hidden-sm"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <vue-final-modal
    v-model="modalState.apr"
    :esc-to-close="true"
    content-class="modal-content"
    classes="modal-container"
    @closed="closeModal"
  >
    <modal-content @closed="closeModal" :slug="'apr'" />
  </vue-final-modal>
  <vue-final-modal
    v-model="modalState.del"
    :esc-to-close="true"
    content-class="modal-content"
    classes="modal-container"
    @closed="closeModal"
  >
    <modal-content @closed="closeModal" :slug="'del'" />
  </vue-final-modal>
</template>

<script>
import { contactData } from '../../constants'
import { VueFinalModal } from 'vue-final-modal'

import ModalContent from './ModalContent.vue'

import { reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'Projects',
  components: {
    VueFinalModal,
    ModalContent
  },
  setup() {
    const modalState = reactive({
      apr: false,
      del: false
    })

    const router = useRouter()
    const route = useRoute()

    const openModal = slug => router.push({ query: { [slug]: true } })
    const closeModal = () => router.replace({ path: '/' })

    watch(
      () => route.fullPath,
      val => {
        val === '/'
          ? Object.keys(modalState).forEach(slug => (modalState[slug] = false))
          : (modalState[Object.keys(route.query)[0]] = true)
      },
      { immediate: true }
    )

    return { contactData, modalState, openModal, closeModal }
  }
}
</script>

<style>
.zoom-7 {
  zoom: 0.7;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;

  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

@media (min-width: 320px) {
  .modal-content {
    max-width: 100%;
    max-height: 100% !important;
    padding: 2rem;
  }
}
@media (min-width: 641px) {
  .modal-content {
    max-width: 65%;
    padding: 5rem;
  }
}

@media (min-width: 1920px) {
  .modal-content {
    max-width: 1267px !important;
  }
}
</style>
