<template>
  <footer
    class="bg-cover skin-dark-footer"
    style="background: #0b4f6c url(assets/img/footer/footer-bg.png) no-repeat"
  >
    <div class="footer-bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12 footer-links">
            <p v-for="(menuItem, index) in menuItems" :key="index">
              <a
                href="#"
                @click.stop.prevent="scrollToTargetAdjusted(menuItem.link)"
                >{{ $t(`menu.${menuItem.link}`) }}</a
              >
            </p>
          </div>
          <div class="col-lg-4 col-md-12 text-center">
            <img
              src="assets/img/footer/signature.png"
              class="footer-image footer-image-small"
            /><br />
            <img src="assets/img/logo-light.png" class="footer-image" />
            <p class="footer-text">
              2022 © Website by
              <a :href="`mailto:${designerEmail}`">shxtov</a>
              for Fonteluna SA
            </p>
          </div>
          <div
            class="col-lg-4 col-md-12 text-right d-none d-lg-block footer-links"
          >
            <p>
              {{ contactData.businessName }}
            </p>
            <p>
              {{ contactData.addressShort }}
            </p>
            <p>
              <a :href="`tel:${contactData.tel}`">{{ contactData.tel }}</a>
            </p>
            <p>
              <a :href="`mailto:${contactData.email}`">{{
                contactData.email
              }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { scrollToTargetAdjusted } from '../util'
import { menuItems, designerEmail, contactData } from '../constants'

export default {
  name: 'Footer',
  setup() {
    return { menuItems, scrollToTargetAdjusted, designerEmail, contactData }
  }
}
</script>

<style>
.footer-image {
  max-height: 100px;
  width: auto;
  margin-right: 10px;
  margin-left: 10px;
}
.footer-image-small {
  max-height: 80px;
}
.footer-text {
  margin-top: 10px;
  font-size: 12px;
}
.footer-links {
  font-size: 14px;
}

.footer-links a:hover,
.footer-text a:hover {
  text-decoration: underline;
}
</style>
