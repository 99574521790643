<template>
  <div class="hero-banner" id="home">
    <div class="hero-img ct-slick-homepage" data-autoplay="true">
      <div
        class="ct-header slick-slide-animate tablex item"
        v-for="(item, index) in sliderImages"
        :key="index"
        :data-background="`assets/img/${sliderImagesPath}${item}`"
      >
        <div class="ct-u-display-tablex">
          <div class="inner"></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col col-lg-12">
          <div class="content light">
            <img
              src="assets/img/logo-slider.png"
              alt=""
              class=""
              style="width: 100%"
            />
            <div class="btn-wrap mt-4 text-center">
              <a
                href="#"
                @click.stop.prevent="scrollToTargetAdjusted('about-us')"
                class="btn btn-info btn-md"
                >{{ $t('slider.readmore') }} <i class="ti-angle-down zoom-7"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scrollToTargetAdjusted } from '../util'

export default {
  name: 'Slider',
  setup() {
    const sliderImagesPath = 'slider/'
    const sliderImages = ['1.jpeg', '2.jpeg', '3.jpeg']
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)

    return { sliderImagesPath, sliderImages, scrollToTargetAdjusted }
  }
}
</script>
<style>
.zoom-7 {
  zoom: 0.7;
}
</style>
