<template>
  <section id="about-us">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <div class="sec-heading mx-auto">
            <h1>{{ $t('about.title') }}</h1>
            <p>{{ $t('about.subtitle') }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-justify">
          <p v-html="$t('about.text')"></p>
        </div>
      </div>

      <div class="row mrg-top-20">
        <div class="col-lg-4 col-md-4 mb-4">
          <div
            class="features-thumb-box"
            data-aos="fade-down"
            data-aos-duration="600"
          >
            <img
              src="assets/img/about/1.JPG"
              class="img-fluid mx-auto"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 mb-4">
          <div
            class="features-thumb-box"
            data-aos="fade-down"
            data-aos-duration="1200"
          >
            <img
              src="assets/img/about/2.JPG"
              class="img-fluid mx-auto"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 mb-4">
          <div
            class="features-thumb-box"
            data-aos="fade-down"
            data-aos-duration="1800"
          >
            <img
              src="assets/img/about/3.JPG"
              class="img-fluid mx-auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style></style>
