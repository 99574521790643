import { createRouter, createWebHistory } from 'vue-router'

import Index from '../views/Index'
import MaintenanceMode from '../views/MaintenanceMode'

const routes =
  process.env.VUE_APP_MAINTENANCE_MODE === 'true'
    ? [{ path: '/:catchAll(.*)', component: MaintenanceMode }]
    : [{ path: '/', component: Index }]

export default createRouter({
  history: createWebHistory(),
  routes
})
