export const scrollToTargetAdjusted = id => {
  const element = document.getElementById(id)
  const headerOffset = 70
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}
