<template>
  <vue-easy-lightbox
    scrollDisabled
    moveDisabled
    :visible="galleryVisible"
    :imgs="slides"
    @hide="galleryVisible = false"
  >
    <template v-slot:close-btn="{ close }">
      <button class="btn btn-secondary btn-sm modal__close" @click="close">
        <i class="ti-close"></i></button
    ></template>
    <template v-slot:toolbar=""> </template>
    ></vue-easy-lightbox
  >
  <button class="btn btn-secondary btn-sm modal__close" @click="closeModal">
    <i class="ti-close"></i>
  </button>
  <div class="modal__content" id="cont" v-if="slug === 'apr'">
    <div class="container-fluid">
      <div class="row apr-row">
        <div class="col-lg-12">
          <h2>Aprelskoye deposit, Kyrgyzstan</h2>
          <h4>Deposit location and geological position</h4>
          <p>
            The Aprelskoye deposit (area about 3 km2) is located on the northern
            spurs of the Turkestan ridge, in the basin of the Almaly stream, a
            left tributary of the river. Leilek is in its middle course and
            occupies the central part of the Almaly ore field.
          </p>
          <p>
            The field area is located in the middle-upper Paleozoic activation
            zone of the High Foothills of the Turkestan Range, adjacent to the
            deep Karavshinsky (Kichi-Alai) fault from the north.
          </p>
          <p>
            The deposit belongs to the porphyry type deposits. Porphyry deposits
            are ore bodies associated with porphyry intrusions and the
            hydrothermal fluids that accompany these intrusions as they move and
            cool.
          </p>
          <p>
            Six independent areas have been identified at the field, three of
            which are of industrial interest. These are the Central, Rodnikovy
            and Medny sections.
          </p>
          <p>
            Administratively, the field area belongs to the Leilek district of
            the Batken region of the Kyrgyz Republic.
          </p>
          <p>The area of the license area is 8.0 km2.</p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Infrastructure</h4>
          <p>
            The distance from the regional center of Batken to the field is 120
            km. The nearest railway station is 110 km away in Khujand (Republic
            of Tajikistan). The nearest village is Ozgorush - located 6 km from
            the deposit.
          </p>
          <p>
            A road has been built to the deposit. Bulldozer geological
            exploration roads were also built directly at the field.
          </p>
          <p>
            The nearest source of electricity is the substation TP-35/10 kV
            “Teshik” in the village Katran, from which a 10 kV power line is
            stretched to the village of Ozgurush.
          </p>
          <p>The nearest water source is the river Leilek</p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Work carried out</h4>
          <p>
            The Aprelskoye deposit was discovered by Soviet geologists of the
            South Kyrgyz geological expedition in 1968. Surface work was carried
            out at the deposit - ditches and pits; based on the results of the
            work, predicted reserves of gold and silver were determined.
          </p>
          <p>
            After the above-mentioned work was carried out in the 70s, no
            geological exploration work was carried out at the Aprelskoye field
            for a long time. Targeted geological exploration of the deposit was
            resumed only in 2003, after our company Aprelskoye EZetI LLC (A.Z.
            International LLC) received a license for geological exploration of
            the Aprelskoye deposit.
          </p>
          <p>
            In 2003, old ditches were opened and new ones were passed, furrow
            samples were taken and their chemical and analytical study was
            carried out. Pioneering studies of the technological properties of
            ore from the deposit were carried out.
          </p>
          <p>
            The results of this work confirmed the data of predecessors and
            revealed additional prospects for the field. The results of earlier
            work in 2003-2005 made it possible to attract additional funding for
            the geological study of the site. In 2006, based on the geological
            assignment, a detailed exploration project for the field was
            developed. The project passed all the necessary approvals and
            examinations and was allowed to be implemented.
          </p>
          <p>
            Based on the complexity of the geological structure, the design
            adopted the mining-drilling method of exploration of the field.
          </p>
          <p>
            The exploration methodology included core drilling of wells and
            excavation of geological exploration adits.
          </p>
          <p>
            During the period from 2003 to 2007, the following volume of
            geological exploration work was carried out:
          </p>
          <ul class="simple-list">
            <li>internal roads and drilling sites were built;</li>
            <li>132 km of geological routes were covered;</li>
            <li>4190 linear meters drilled core wells;</li>
            <li>drifting of adits – 375 linear meters;</li>
            <li>4272 m3 of ditches passed;</li>
            <li>2525 l.m. cutting-in;</li>
            <li>
              selected and analyzed in laboratories of the Kyrgyz Republic -
              5000 samples
            </li>
            <li>
              3 technological studies of ore for dressing were carried out;
            </li>
          </ul>
          <p>
            Based on the results of the work, a feasibility study of conditions
            with calculation of reserves was compiled and submitted to the State
            Reserves Committee of the Kyrgyz Republic.
          </p>
          <p>
            Six independent areas have been identified at the field, three of
            which are of industrial interest. These are the Central, Rodnikovy
            and Medny sections. The Intermediate, Northern and Southern areas
            require further study.
          </p>
          <p>
            In 2012, the work that had begun was stopped due to force majeure.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Deposit resources</h4>
          <p>
            A report with calculation of reserves and feasibility study of
            conditions in 2007 was compiled by the specialized company
            Aziyarudproekt.
          </p>
          <p>
            The reserves taken into account for categories C1 and C2 according
            to the State Reserves Committee protocol were:
          </p>
          <ul class="simple-list">
            <li>for the Central section - 0.87 t at avg. sod. 7.06 g/t</li>
            <li>for the Medny section 2.24t at avg. sod. 1.09 g/t</li>
            <li>
              at the Rodnikovy site, reserves are accepted as off-balance sheet
            </li>
          </ul>
          <p>
            In 2019 – 20 Aziyarudproekt company compiled a report on reserve
            recalculation.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <p>
            Reserves in categories C1 and C2 were tested and accepted on the
            state balance sheet in 2021 in the following quantities:
          </p>
          <ul class="simple-list">
            <li>for the Central section - 0.97 t at avg. sod. 5.99 g/t</li>
            <li>at the Rodnikovy site - 2.13 tons at avg. sod. 0.76 g/t</li>
            <li>
              At the Medny site, reserves in category C2 remained taken into
              account (for additional exploration) in the amount of: 2.24 tons
              with avg. sod. 1.09 g/t
            </li>
          </ul>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Deposit potential</h4>
          <p>
            It is planned to continue geological exploration work at the Medny
            site to transfer the reserves taken into account to the industrial
            category.
          </p>
          <p>
            Also, the current project plans to further explore predicted
            resources in previously identified ore zones and transfer them to
            industrial categories. As part of the local project, it is planned
            to conduct pilot mining at the sites to clarify the technological
            regimes for ore enrichment.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Process flow diagram for enrichment</h4>
          <p>Enrichment technology:</p>
          <ul class="simple-list">
            <li>
              Rodnikovy section – oxidized ores – heap leaching, primary ores –
              flotation, vat leaching. (final product doré alloy, gold
              concentrate)
            </li>
            <li>Medny section - heap leaching (end product Dore alloy)</li>
            <li>
              Central section – flotation, with cyanidation of flotation tails
              (the final product is gold-containing concentrate, Doré alloy)
            </li>
          </ul>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Degree of readiness for mastery</h4>
          <p>
            Currently, the development of a technical project for the
            development of the Bonantsevaya zone (Central section) and the
            Rodnikovy section is being completed. A pilot mining project has
            also been developed to test enrichment modes using heap leaching.
            Pre-design work was carried out on the technological road between
            the deposit and the processing site. Work was carried out on
            geo-ecological risk assessment for the field development stage.
          </p>
          <h4>Working conditions</h4>
          <p>
            The occurrence of ore zones, their thickness, mining conditions
            suggest open and combined methods of mining the deposit: quarry -
            Rodnikovy site, quarry and underground - Central site
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Material resources for work</h4>
          <p>
            For geological exploration and mining (open pit) work, the company
            has the following types of equipment:
          </p>
          <ul class="simple-list">
            <li>Excavators</li>
            <li>Bulldozers</li>
            <li>Drilling rig for blast holes</li>
            <li>Drilling rig for drilling exploration wells</li>
            <li>Compressors</li>
          </ul>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-lg-12">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-2"
            @click="galleryVisible = true"
          >
            <i class="ti-gallery mr-2" />
            Gallery
          </button>
          <a
            href="doc/apr_eng.pdf"
            target="_blank"
            type="button"
            class="btn btn-secondary btn-lg mr-2"
          >
            <i class="ti-eye mr-2" />
            Presentation
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal__content" id="cont" v-else-if="slug === 'del'">
    <div class="container-fluid">
      <div class="row apr-row">
        <div class="col-lg-12">
          <h2>Delbek and Tutek fields, Kyrgyzstan</h2>
          <h4>Location of deposits and geological position</h4>
          <p>
            The Delbek-Tutek area (Delbek and Tutek fields) is located in the
            highlands of Southern Kyrgyzstan on the northern slope of the
            Turkestan Range, in the territory of the Batken district of the
            Batken region. The distance from Bishkek to the administrative
            center of Batken along the existing state highway is 920 km. There
            is an operating airport in Batken; with regular flights
            Bishkek-Batken-Bishkek.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Infrastructure</h4>
          <p>
            From the city of Batken, Delbek-Tutek Square is located at a
            distance of 116 km. The nearest high-voltage power line is 30 km
            away. The nearest settlement is the village of Zardaly (Korgon)
            located 23 km down the Ak-Terek River. The area contains two fields,
            Delbek and Tutek, which consists of three sections Tutek-1 (North
            and South) and Tutek-2.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Topography and climate</h4>
          <p>
            The relief of the region is mountainous, sharply dissected. Absolute
            elevations: minimum - 2100 m, maximum - St. 4000 m (at the southern
            border of the area.)
          </p>
          <p>
            The Delbec and Tutek fields themselves are located in the altitude
            range from 2400 to 3700 m above sea level.
          </p>
          <p>
            The work area is characterized by a sharply continental, harsh
            climate. Summer is short and often rainy; winter is cold and snowy.
            Precipitation occurs throughout the year: in the summer months - in
            the form of rain and hail, and the rest of the time in the form of
            snow. At altitudes above 3500m, areas with permafrost are developed.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Geological description of objects</h4>
          <p>
            Within the Delbek-Tutek license area, previous work has identified
            tantalum and niobium deposits of Delbek and Tutek (Tutek-I and
            Tutek-II sites) and a number of occurrences of mineralization of a
            similar type. In addition, ore occurrences and points of
            mineralization of polymetals, copper, gold, tungsten, uranthorium,
            antimony, molybdenum, mercury and rare earths are known here.
          </p>
          <p>
            Shlikhov and lithochemical testing revealed scheelite dispersion
            halos, anomalies, tin, lithium, copper, molybdenum, and gold flows.
          </p>
          <p>
            Elevated tantalum oxide contents with relatively low niobium
            contents have been established in varieties of vein, metasomatic,
            igneous and volcanic rocks.
          </p>
          <p>
            Of greatest interest are the high concentrations of tantalum and
            niobium oxide in productive vein formations confined to the
            exocontacts of the Matchaya massif, composed of granites, quartz
            syenites of the 1st phase and alkaline and nepheline syenites of the
            2nd phase of the Matchaya complex.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Resources of the Tutek deposit</h4>
          <p>Section Tutek -1 (North and South)</p>
          <p>
            0 quartz-albitite and 16 carbonatite veins were mapped on the site;
            for 5 of the assessed ones (No1-No5), the predicted resources of
            Ta2O5 were calculated for category P1, which amounted to 4816.5
            tons. Section Tutek-2 (Zhynnysu) 16 quartz and 12 quartz-albite
            veins are mapped here.
          </p>
          <p>
            Based on the assessment results, 10 ore bodies (No6-No15) were
            identified with the calculation of the predicted resources of Ta2O5
            in category P1, amounting to 11290,4 tons. In addition, for part of
            vein No11 and vein No12, the predicted resources of Nb2O5 in
            category P1 were calculated and amounted to 337.4 tons.
          </p>
          <p>
            Calculation of the predicted resources of U and Th in category P1
            was made for albite-quartz veins NoNo7, 8, 10, 11, 12. The following
            calculated parameters were adopted: the average length of ore bodies
            is 416 m, the total thickness is 10 m, the average suspension along
            the dip is 31 m, the average U content - 0.074%, Th - 0.19%, which
            is below the minimum content required by the industry. The predicted
            resources of uranium are 2399 tons, thorium - 6514 tons.
          </p>
          <p>
            Based on the constant presence of industrial contents of tantalum
            oxides in all types of vein formations for unvalued veins, the
            predicted resources of Ta2O5 for category P2 in the amount of
            14527.6 tons were also calculated.
          </p>
        </div>
      </div>
      <div class="row apr-row">
        <div class="col-lg-12">
          <h4>Resources of the Delbek deposit</h4>
          <p>
            For the Delbek deposit, within the assessed zone, according to
            sampling data, 4 ore bodies were identified, for which the predicted
            resources of Ta2O5 were calculated according to cat. P1.
          </p>
          <p>
            When calculating resources, the suspension depth was taken to be
            equal to half the projection of the length of the ore bodies, the
            minimum industrial Ta2O5 content was taken to be 0.017%.
          </p>
          <p>
            Ore body 1. Weighted average content of Ta2O5 - 0.037%, maximum -
            0.058%.
          </p>
          <p>
            Ore body 2. Weighted average content of Ta2O5 - 0.041%, maximum
            0.101%. In one sample, an increased content of Y203 was noted -
            0.145%.
          </p>
          <p>
            Ore body 3. The weighted average content of Ta2O5 is very high -
            0.168%, maximum 0.380. The ore body is characterized by the lowest
            coefficient ore content - 0.19.
          </p>
          <p>
            Ore body 4. The weighted average content of Ta2O5 is 0.023%, the
            maximum is 0.047%.
          </p>
          <p>
            Pravoberezhny section, maximum - 0.006%. The Nb2O5 contents are
            comparable to those in samples from the left bank and amount to
            0.05-0.036%. In addition to those assessed, 6 more isolated bodies
            of carbonate metasomatic rocks have been mapped at the field, some
            of which have undergone lithochemical profiles. By analogy with the
            assessed ore bodies, predicted resources for category P2 were
            calculated for them.
          </p>
          <p>
            The average content of 0.067% and the ore bearing coefficient of
            0.34 adopted for calculation are averaged values for the assessed
            ore bodies.
          </p>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-lg-12">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-2"
            @click="galleryVisible = true"
          >
            <i class="ti-gallery mr-2" />
            Gallery
          </button>
          <a
            href="doc/delbek_tutek_eng.pdf"
            target="_blank"
            type="button"
            class="btn btn-secondary btn-lg mr-2"
          >
            <i class="ti-eye mr-2" />
            Presentation
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  name: 'ModalContent',
  emits: ['closed'],
  props: ['slug'],
  setup(props, { emit }) {
    const closeModal = () => {
      emit('closed')

      document.getElementById('cont').scroll({ top: 0 })
    }

    const slides = computed(() => {
      if (props.slug === 'apr') {
        return [
          '/assets/img/projects/apr/1.png',
          '/assets/img/projects/apr/2.jpg',
          '/assets/img/projects/apr/1.webp',
          '/assets/img/projects/apr/2.webp',
          '/assets/img/projects/apr/3.webp',
          '/assets/img/projects/apr/4.webp',
          '/assets/img/projects/apr/3.jpg',
          '/assets/img/projects/apr/4.jpg',
          '/assets/img/projects/apr/5.jpg',
          '/assets/img/projects/apr/6.jpg',
          '/assets/img/projects/apr/7.jpg',
          '/assets/img/projects/apr/8.jpg',
          '/assets/img/projects/apr/9.jpg',
          '/assets/img/projects/apr/10.jpg',
          '/assets/img/projects/apr/11.jpg',
          '/assets/img/projects/apr/12.jpg',
          '/assets/img/projects/apr/13.jpg',
          '/assets/img/projects/apr/14.jpg'
        ]
      } else if (props.slug === 'del') {
        return [
          'assets/img/projects/del/1.jpg',
          'assets/img/projects/del/2.jpg',
          'assets/img/projects/del/3.jpg',
          'assets/img/projects/del/4.jpg',
          'assets/img/projects/del/5.jpg',
          'assets/img/projects/del/6.jpg',
          'assets/img/projects/del/7.jpg',
          'assets/img/projects/del/8.jpg',
          'assets/img/projects/del/9.jpg'
        ]
      } else {
        return []
      }
    })

    const galleryVisible = ref(false)

    return { closeModal, slides, galleryVisible }
  }
}
</script>

<style lang="scss">
.modal__content {
  flex-grow: 1;
  overflow-y: auto;

  & h2 {
    margin-bottom: 20px;
  }

  & h4 {
    margin-bottom: 15px;
  }

  & p {
    text-align: justify;
    line-height: 1.5rem;
  }
}

.modal__close {
  position: absolute;
  line-height: 1.25rem;
  cursor: pointer;
}

.apr-img {
  width: 100%;
}

.apr-row {
  margin-bottom: 2rem;
}

.btn-secondary {
  color: #0b4f6c !important;
}

@media (min-width: 320px) {
  .modal__close {
    top: 0.75rem;
    right: 0.75rem;
  }
}

@media (min-width: 641px) {
  .modal__close {
    top: 1rem;
    right: 1rem;
  }
}
</style>
