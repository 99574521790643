<template>
  <div class="col-lg-7 col-md-7">
    <div class="contact-form">
      <form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="form-group col-md-6">
            <input
              type="text"
              class="form-control"
              :class="{ 'input-error': errors.name }"
              :placeholder="$t('contact.yourName')"
              :disabled="formLoading"
              name="name"
              v-model="name"
            />
            <span class="input-error-message">{{ errors.name }}</span>
          </div>
          <div class="form-group col-md-6">
            <input
              type="email"
              class="form-control"
              :class="{ 'input-error': errors.email }"
              :placeholder="$t('contact.yourEmail')"
              :disabled="formLoading"
              name="email"
              v-model="email"
            />
            <span class="input-error-message">{{ errors.email }}</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :class="{ 'input-error': errors.subject }"
            :placeholder="$t('contact.subject')"
            :disabled="formLoading"
            name="subject"
            v-model="subject"
          />
          <span class="input-error-message">{{ errors.subject }}</span>
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            :class="{ 'input-error': errors.message }"
            :placeholder="$t('contact.text')"
            :disabled="formLoading"
            name="message"
            v-model="message"
          ></textarea>
          <span class="input-error-message">{{ errors.message }}</span>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="(errors && Object.keys(errors).length > 0) || formLoading"
        >
          {{ formLoading ? $t('loading') : $t('contact.sendButton') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import { useForm, useField } from 'vee-validate'
import * as rules from 'yup'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: 'Form',
  setup() {
    const { t } = useI18n()

    const contactFormSchema = rules.object({
      name: rules
        .string()
        .required(t('validation.required'))
        .min(5, t('validation.min', ['5']))
        .max(50, t('validation.max', ['50'])),
      email: rules
        .string()
        .required(t('validation.required'))
        .email(t('validation.email')),
      subject: rules
        .string()
        .required(t('validation.required'))
        .min(10, t('validation.min', ['10']))
        .max(50, t('validation.max', ['50'])),
      message: rules
        .string()
        .required(t('validation.required'))
        .min(10, t('validation.min', ['10']))
        .max(2000, t('validation.max', ['2000']))
    })

    const { errors, handleSubmit } = useForm({
      validationSchema: contactFormSchema
    })

    const { value: name } = useField('name')
    const { value: email } = useField('email')
    const { value: subject } = useField('subject')
    const { value: message } = useField('message')

    const formLoading = ref(false)

    const toast = useToast()
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const axios = inject('axios')

    const onSubmit = handleSubmit(
      async (values, { resetForm }) => {
        await recaptchaLoaded()
        const token = await executeRecaptcha('contactForm')

        formLoading.value = true

        axios
          .post(`/api/send`, {
            token,
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value
          })
          .then(() => {
            resetForm()
            toast.success(t('message-sent'))
          })
          .catch(() => toast.error('Internal server error'))
          .finally(() => (formLoading.value = false))
      },
      () => toast.error(t('validation.check-form'))
    )

    return {
      name,
      email,
      subject,
      message,
      errors,
      onSubmit,
      formLoading
    }
  }
}
</script>

<style>
.input-error {
  box-shadow: 0 0 2px #da291c;
  background-color: #faeded;
}

.input-error-message {
  color: #da291c;
  font-size: 14px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #da291c;
  border-color: #da291c;
}
</style>
