<template>
  <div class="header header-light shadow">
    <div class="container">
      <nav id="navigation" class="navigation navigation-landscape">
        <div class="nav-header">
          <a
            class="nav-brand fixed-logo"
            href="#"
            @click.stop.prevent="scrollToTargetAdjusted('home')"
            ><img src="assets/img/logo.png" class="logo" alt=""
          /></a>
          <div class="nav-toggle"></div>
        </div>
        <div class="nav-menus-wrapper" style="transition-property: none">
          <ul class="nav-menu">
            <li v-for="(menuItem, index) in menuItems" :key="index">
              <a
                href="#"
                @click.stop.prevent="scrollToTargetAdjusted(menuItem.link)"
                >{{ $t(`menu.${menuItem.link}`) }}</a
              >
            </li>
          </ul>

          <!--<ul class="nav-menu nav-menu-social align-to-right">
            <li>
              <a
                >{{ locale.toUpperCase() }}
                <span class="submenu-indicator"></span
              ></a>
              <ul class="nav-dropdown nav-submenu">
                <template
                  v-for="(availableLocale, index) in availableLocales"
                  :key="index"
                  ><li
                    style="cursor: pointer"
                    v-if="locale !== availableLocale"
                  >
                    <a
                      @click="setLocale(availableLocale)"
                      :disabled="locale === availableLocale"
                      >{{ availableLocale.toUpperCase() }}</a
                    >
                  </li></template
                >
              </ul>
            </li>
          </ul> -->
        </div>
      </nav>
    </div>
  </div>
  <div class="clearfix"></div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { scrollToTargetAdjusted } from '../util'
import { menuItems } from '../constants'
import { useToast } from 'vue-toastification'

export default {
  name: 'Header',
  setup() {
    const { t, locale, availableLocales } = useI18n()
    const toast = useToast()

    const setLocale = newLocale => {
      localStorage.setItem('locale', newLocale)
      locale.value = newLocale
      toast.success(t('locale-set'))
    }

    return {
      menuItems,
      scrollToTargetAdjusted,
      setLocale,
      locale,
      availableLocales
    }
  }
}
</script>

<style>
.Vue-Toastification__toast-body {
  font-size: 14px !important;
}
</style>
