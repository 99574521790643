export default {
  menu: {
    'about-us': 'About us',
    projects: 'Our projects',
    investors: 'To investors',
    contact: 'Contact'
  },
  slider: {
    readmore: 'Read more'
  },
  about: {
    title: 'About us',
    subtitle:
      'Fonteluna SA, a dynamically developing Swiss company, was founded in 2020',
    text: 'The company carries out targeted investment activities in projects related to the development and geological exploration of gold, tantalum and antimony at subsoil use sites.<br><br>Fonteluna SA has an outstanding management team with significant domestic and international experience in the mining industry, from discovery through exploration to production.<br><br>The main principles of our company are business transparency, compliance with environmental standards, and the introduction of the latest innovative technologies at all stages of production processes.<br><br>Fonteluna SA strives to maintain a favorable social environment in the regions where it operates and at each of its enterprises, provides stable employment and assists in solving pressing problems in the regions where it operates.'
  },
  projects: {
    title: 'Our projects',
    subtitle:
      'Fonteluna SA is involved in the exploration and development of gold deposits',
    aprTitle: 'Aprelskoye deposit, Kyrgyzstan',
    aprSubtitle:
      'The Aprelskoye deposit (area about 3 km2) is located on the northern spurs of the Turkestan ridge, in the basin of the Almaly stream, a left tributary of the river. Leilek is in its middle course and occupies the central part of the Almaly ore field.',
    aprReadmore: 'Read more',
    delTitle: 'Delbek and Tutek fields, Kyrgyzstan',
    delSubtitle:
      'The Delbek-Tutek area (Delbek and Tutek fields) is located in the highlands of Southern Kyrgyzstan on the northern slope of the Turkestan Range, in the territory of the Batken district of the Batken region. The distance from Bishkek to the administrative center of Batken along the existing state highway is 920 km. There is an operating airport in Batken; with regular flights Bishkek-Batken-Bishkek.',
    delReadmore: 'Read more',
    name: 'Fonteluna SA',
    subtitle2:
      ' is a fast-growing and promising player in the gold mining industry',
    text1:
      'Our company is actively engaged in the development of new fields and the development of new projects. In addition, cooperation with investors is a priority for us.',
    text2:
      'Were we able to interest you? Contact us via the contact form or send us an email to'
  },
  investors: {
    title: 'To investors',
    text: 'You, as a potential investor or interested party, can familiarize yourself with the information you are interested in regarding the company Fonteluna SA and its projects. If you wish, you can request the following information:',
    point1: 'Key indicators',
    point2: 'Financial results',
    point3: 'Annual reports',
    point4: 'Project presentations',
    bottomText:
      'To make a request, please go to the contact form below or send us an email to'
  },
  contact: {
    title: 'Contact',
    subtitle: 'Contact us if you have questions or want to cooperate',
    name: 'Name',
    address: 'Main office',
    email: 'E-mail',
    phone: 'Telephone',
    yourName: 'Your name',
    yourEmail: 'Your e-mail',
    subject: 'Subject',
    text: 'Your message',
    sendButton: 'Send'
  },
  validation: {
    'check-form': 'Please check the validity of form fields!',
    required: 'This field is required',
    min: 'Minimum length is {0} symbols',
    max: 'Maximum length is {0} symbols',
    email: 'Please check the validity of e-mail field'
  },
  'locale-set': 'Language preferences saved',
  'message-sent': 'Your message has been sent.',
  loading: 'Loading...'
}
