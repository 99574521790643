export default {
  menu: {
    'about-us': 'О нас',
    projects: 'Наши проекты',
    investors: 'Инвесторам',
    contact: 'Контакт'
  },
  slider: {
    readmore: 'Узнать больше'
  },
  about: {
    title: 'О нас',
    subtitle:
      'Fonteluna SA, динамично развивающаяся швейцарская компания, создана в 2020 году',
    text: 'Компания осуществляет целевую инвестиционную деятельность в проекты, связанные с разработкой и геологическим изучением на золото, тантал и сурьму на объектах недропользования.<br><br>Компания «Fonteluna SA» имеет выдающуюся управленческую команду, обладающую значительным опытом работы в стране и международным опытом в горнодобывающей промышленности, начиная от открытия и разведки до добычи.<br><br>Основными принципами нашей компании являются прозрачность ведения бизнеса, соблюдение экологических норм, внедрение новейших инновационных технологий на всех стадиях производственных процессов.<br><br>Компания «Fonteluna SA» стремится поддерживать благоприятную социальную среду в регионах присутствия и на каждом из своих предприятий, обеспечивает стабильную занятость и содействует в решении актуальных проблем в регионах присутствия.'
  },
  projects: {
    title: 'Наши проекты',
    subtitle:
      'Fonteluna SA участвует в разведке и освоении месторождений золота',
    aprTitle: 'Месторождение Апрельское, Киргизия',
    aprSubtitle:
      'Месторождение Апрельское расположено на северных отрогах Туркестанского хребта, в бассейне ручья Алмалы – левого притока р. Лейлек в ее среднем течении и занимает центральную часть Алмалинского рудного поля.',
    aprReadmore: 'Читать дальше',
    delTitle: 'Месторождения Дельбек и Тутек, Киргизия',
    delSubtitle:
      'Дельбек-Тутекская площадь (месторождения Дельбек и Тутек) расположена в высокогорной части Южного Кыргызстана на северном склоне Туркестанского хребта, на территории Баткенского района Баткенской области.',
    delReadmore: 'Читать дальше',
    name: 'Fonteluna SA',
    subtitle2:
      '- быстроразвивающийся и перспективный игрок в сфере золотодобычи',
    text1:
      'Наша компания активно занимается освоением новых месторождений и развитием новых проектов. Кроме того, сотрудничество с инвесторами является для нас приоритетом.',
    text2:
      'Мы смогли Вас заинтересовать? Свяжитесь с нами посредством формы контакта либо отправьте нам электронное письмо на адрес'
  },
  investors: {
    title: 'Инвесторам',
    text: 'Вы, как потенциальный инвестор или заинтересованное лицо, можете ознакомиться с интересующей Вас информацией касательно компании Fonteluna SA и ее проектов. При желании, Вы можете запросить следующую информацию:',
    point1: 'Ключевые показатели',
    point2: 'Финансовые результаты',
    point3: 'Годовые отчеты',
    point4: 'Презентации проектов',
    bottomText:
      'Для оформления запроса, перейдите к форме контакта ниже либо отправьте нам электронное письмо на адрес'
  },
  contact: {
    title: 'Контакт',
    subtitle:
      'Свяжитесь с нами, если у Вас есть вопросы или желание сотрудничать',
    name: 'Наименование',
    address: 'Главный офис',
    email: 'Электронная почта',
    phone: 'Телефон',
    yourName: 'Ваше имя',
    yourEmail: 'Ваш адрес электронной почты',
    subject: 'Тема письма',
    text: 'Ваше сообщение',
    sendButton: 'Отправить письмо'
  },
  validation: {
    'check-form': 'Проверьте корректность введенных данных',
    required: 'Это поле обязательно',
    min: 'Минимальная длина - {0} символов',
    max: 'Максимальная длина - {0} символов',
    email: 'Проверьте корректность введенного e-mail'
  },
  'locale-set': 'Настройки языка сохранены',
  'message-sent': 'Ваше сообщение отправлено',
  loading: 'Загрузка...'
}
