<template>
  <section class="p-0 image-bg" id="investors">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 p-0 image-block">
          <div class="image-block-holder">
            <div
              class="image-block-holder-img"
              style="
                background: url('assets/img/invest/invest.jpg');
                opacity: 1;
              "
            >
              <img
                src="assets/img/invest/invest.jpg"
                class="img-responsive img-holder"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 p-0">
          <div
            class="image-block-content bg-dark-blue inverse-color"
            style="
              background: #0b4f6c url(assets/img/invest/invest-bg.png) no-repeat;
              background-size: cover;
            "
          >
            <h1 data-aos="fade-right" data-aos-duration="600">
              {{ $t('investors.title') }}
            </h1>
            <p
              data-aos="fade-right"
              data-aos-duration="600"
              class="text-justify"
            >
              {{ $t('investors.text') }}
            </p>
            <ul class="simple-list">
              <li
                data-aos="fade-right"
                data-aos-duration="600"
                data-aos-delay="300"
              >
                {{ $t('investors.point1') }}
              </li>
              <li
                data-aos="fade-right"
                data-aos-duration="600"
                data-aos-delay="600"
              >
                {{ $t('investors.point2') }}
              </li>
              <li
                data-aos="fade-right"
                data-aos-duration="600"
                data-aos-delay="900"
              >
                {{ $t('investors.point3') }}
              </li>
              <li
                data-aos="fade-right"
                data-aos-duration="600"
                data-aos-delay="1200"
              >
                {{ $t('investors.point4') }}
              </li>
            </ul>
            <p
              style="margin-top: 20px"
              class="text-justify"
              data-aos="fade-right"
              data-aos-duration="600"
              data-aos-delay="1500"
            >
              {{ $t('investors.bottomText') }}
              <a :href="`mailto:${contactData.email}`" class="invest-link">{{
                contactData.email
              }}</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
import { contactData } from '../constants'

export default {
  name: 'Investors',
  setup() {
    return { contactData }
  }
}
</script>

<style>
.invest-link {
  color: white !important;
}

.invest-link:hover {
  color: #27b0e9 !important;
}
</style>
