<template>
  <g-map-map
    :center="map.center"
    :zoom="map.zoom"
    :options="map.options"
    map-type-id="terrain"
    class="col-lg-5 col-md-5 image-bg ct-img map"
  >
    <g-map-marker :position="map.center"
  /></g-map-map>
</template>

<script>
export default {
  name: 'Map',
  setup() {
    const map = {
      center: { lat: 46.20322, lng: 6.14805 },
      zoom: 16,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true
      }
    }

    return { map }
  }
}
</script>

<style>
@media (max-width: 641px) {
  .map {
    margin-bottom: 25px;
  }
}
</style>
