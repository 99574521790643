<template>
  <router-view></router-view>
</template>

<script>
import { RouterView } from 'vue-router'

export default {
  name: 'App',
  setup() {
    return { RouterView }
  }
}
</script>

<style lang="scss"></style>
