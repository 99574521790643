const googleMapsConfig = {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY
  }
}

const toastsConfig = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  icon: true,
  rtl: false
}

const recaptchaConfig = {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
}

const menuItems = [
  {
    link: 'about-us'
  },
  {
    link: 'projects'
  },
  {
    link: 'investors'
  },
  {
    link: 'contact'
  }
]

const contactData = {
  email: 'info@fonteluna.ch',
  tel: '+41 22 503 86 06',
  address: 'Place du Molard 9, Geneva 1204, Switzerland',
  addressShort: 'Place du Molard 9, Geneva 1204, CH',
  businessName: 'Fonteluna SA, CHE-298.350.401'
}

const designerEmail = 'shxtov@gmail.com'

export {
  googleMapsConfig,
  recaptchaConfig,
  toastsConfig,
  menuItems,
  contactData,
  designerEmail
}
