import { createApp } from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import i18n from './translations'
import router from './router'
import { googleMapsConfig, recaptchaConfig, toastsConfig } from './constants'
import VueEasyLightbox from 'vue-easy-lightbox'

import App from './App.vue'

const app = createApp(App)

app
  .use(VueGoogleMaps, googleMapsConfig)
  .use(i18n)
  .use(Toast, toastsConfig)
  .use(VueReCaptcha, recaptchaConfig)
  .use(router)
  .use(VueAxios, axios)
  .use(VueEasyLightbox)
  .provide('axios', app.config.globalProperties.axios)
  .mount('#app')
